import "./Home.css"
import Slider from "./Slider"
import images from "../data/fieldPhotosData"
import aboutData from "../data/aboutData"

function Home(props) {
    return (
        <div className={props.darkMode ? "home-dark": "home"} ref={props.inputRef}>
            <Slider images={images} autoPlay={3} />
            <div className="about">
                <h1>About us</h1>
                {aboutData.map((data, index) => {
                    return (
                        <div key={index} className="about-flex-container">
                            <p style={{order: (data.order - 1)}}>{data.text}</p>
                            <img src={data.image} alt={data.alt}/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Home