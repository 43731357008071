import logo from "../assets/images/LatroLogo.png"
import './Header.css';

function Header(props) {
    return (
        <header className={props.darkMode ? "dark": ""} ref={props.inputRef}>
            <img src={logo} className="header-logo" alt="Latro logo"/>
            <h1>Latro Irrigation</h1>
            <div className="toggler">
                <p className="toggler-light">Light</p>
                <div className="toggler-slider" onClick={props.toggleDarkMode}>
                    <div className="toggler-slider-circle"></div>
                </div>
                <p className="toggler-dark">Dark</p>
            </div>
        </header>
    )
}

export default Header