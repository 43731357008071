import image1 from "../assets/images/FieldPhoto1.JPG"
import image2 from "../assets/images/FieldPhoto2.JPG"
import image3 from "../assets/images/FieldPhoto3.JPG"
import image4 from "../assets/images/FieldPhoto4.JPG"
import image5 from "../assets/images/FieldPhoto5.JPG"
import image6 from "../assets/images/FieldPhoto6.JPG"
import image7 from "../assets/images/FieldPhoto7.JPG"
import image8 from "../assets/images/FieldPhoto8.JPG"

const fieldPhotosData = [image1, image2, image3, image4, image5, image6, image7, image8]

export default fieldPhotosData