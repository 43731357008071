import {useEffect, useRef, useState} from 'react'
import './Slider.css'
import BtnSlider from './BtnSlider'

export default function Slider(props) {
    const noOfImages = props.images.length
    const [slideIndex, setSlideIndex] = useState(1)
    
    const nextSlide = () => {
        if(slideIndex !== noOfImages){
            setSlideIndex(slideIndex + 1)
        } 
        else if (slideIndex === noOfImages){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1){
            setSlideIndex(noOfImages)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    // To auto play the slides
    const autoPlayRef = useRef()
    useEffect(()=> {
        autoPlayRef.current = nextSlide
    })
    useEffect(()=> {
        const play = () => {
            autoPlayRef.current()
        }
        if(typeof(props.autoPlay) !== "undefined") {
            const interval = setInterval(play, props.autoPlay * 1000)
            return ()=> clearInterval(interval)
        }
    }, [props.autoPlay])

    // To get the filename to use in alt prop
    const altTextArray = typeof(props.images) !== "undefined" && 
        props.images.map(image => 
            image.split(".")[0].split("/")
                ).map(element => 
                    element[element.length - 1]
                );

    return (
        typeof(props.images) !== "undefined" && 
        <div>
            <div className="container-slider">
                <BtnSlider moveSlide={prevSlide} direction={"prev"}/>
                {props.images.map((image, index) => {
                    return (
                        <div key={index} className={slideIndex === index + 1 ? "slide active-anim" : "slide"}>
                            <img src={image} alt={altTextArray[slideIndex - 1]}/>
                        </div>
                    )
                })}
                <BtnSlider moveSlide={nextSlide} direction={"next"} />
            </div>
            <div className="container-dots">
                {Array.from({length: noOfImages}).map((item, index) => (
                    <div key={index} onClick={() => moveDot(index + 1)} className={slideIndex === index + 1 ? "dot active" : "dot"}></div>
                ))}
            </div>
        </div>
    )
}