import dripper from "../assets/images/Dripper.png"
import plainLateral from "../assets/images/PlainLateral.png"
import flatLateral from "../assets/images/FlatLateral.png"
import sandFilter from "../assets/images/SandFilter.png"
import screenFilter from "../assets/images/ScreenFilter.png"
import hydroCycloneFilter from "../assets/images/HydroCycloneFilter.png"
import venturi from "../assets/images/Venturi.png"
import valves from "../assets/images/Valves.png"
import polyFittings from "../assets/images/PolyFittings.png"

const productsData = [
    {
        title: "DRIPPERS/EMITTERS",
        image: dripper,
        alt: "Dripper",
        text: ["Latro Drippers are manufactured with virgin HDPE material for stable performance.", "Turbulent flow path with wide cross-sectional area makes the Dripper clog resistant.", "The Dripper is openable which helps in cleaning.", "Latro On-line Drippers are made as per IS:13487 - 1992 under BIS License No: CM/L - 3434553."]
    },
    {
        title: "ON-LINE LATERAL",
        image: plainLateral,
        alt: "Plain Lateral",
        text: ["Latro Lateral Pipes are manufactured with LLDPE material which provides durable tubing with the ability to bear UV radiations and environmental stress.", "The smooth inner surface of the lateral minimizes frictional losses and exhibits excellent flow characteristics.", "It is made as per IS:12786 - 1989 under BIS License No: CM/L - 3445659. Available sizes of lateral are 12mm, 16mm and 20mm diameter."]
    },
    {
        title: "IN-LINE LATERAL",
        image: flatLateral,
        alt: "Flat Lateral",
        text: ["Latro Inline Laterals are manufactured with advanced extrusion technique, using LLDPE as raw material.", "The Drippers inside the lateral are equally spaced, fitted tightly which avoids water to jet and ensures uniform discharge of water.", "It is made as per IS:13488 : 2008 under BIS Licence number CM/L No: 6100094898.", "It is available in Dripper spacing of 30cm, 40cm, 50cm, 60cm, 75cm and 90cm. Minimum of 100-micron filtration is recommended. Available sizes of Inline lateral are 12mm and 16mm."]
    },
    {
        title: "SAND FILTER",
        image: sandFilter,
        alt: "Sand Filter",
        text: ["Latro Sand Filter filters out the algae and mud from the water flowing through it.", "Mild steel construction with powder coating protects the Sand Filter against corrosion.", "Easy to open and clean. Has convenient back wash arrangement to clean the residue.", "Maximum operating pressure is 10Kg/cm2.", "Has high filtration efficiency due to the uniform grade of silica sand of size within 0.7mm to 1.20mm.", "It is made as per IS: 14606 : 1998.", "Available sizes are 20m³/hr, 25m³/hr, 30m³/hr, 40m³/hr and 50m³/hr."]
    },
    {
        title: "SCREEN FILTER",
        image: screenFilter,
        alt: "Screen Filter",
        text: ["Latro Screen Filters are made with stainless steel mesh in the inside with high quality plastic body as per IS: 12785 - 89.", "Disc filters are also used for better filtration.", "Available sizes of filters is 10m³/hr, 20m³/hr, 30m³/hr, 40m³/hr and 50m³/hr."]
    },
    {
        title: "HYDROCYCLONE FILTER",
        image: hydroCycloneFilter,
        alt: "Hydro Cyclone Filter",
        text: ["Latro Hydrocyclone Filter is used when bore well water is having more and bigger sizes of sand particles.", "The filter is made of high-quality plastic body as per IS: 14743 - 1999.", "Available sizes of filters are 20m³/hr, 30m³/hr, 40m³/hr and 50m³/hr."]
    },
    {
        title: "FERTIGATION EQUIPMENT",
        image: venturi,
        alt: "Venturi",
        text: ["Latro Venturi Injector efficiently injects fertilizer and chemicals into the water source.", "Available sizes of Venturi Injector are 20mm, 25mm, 40mm, 50mm, 63mm and 75mm. It is made as per IS: 14483 Part I: 1997."]
    },
    {
        title: "VALVES",
        image: valves,
        alt: "Valves",
        text: ["Latro provides high quality control valves and ball valves made of PP.", "It controls flow of water in the head control unit, mainline and sub mainline.", "Air release valve is used to release the air pressure in the system.", "Flush valve is fixed at the end of the submain to clean the residue from the main and sub mainline.", "Non-Return valve prevents the water to flow backwards thus protecting the motor."]
    },
    {
        title: "ACCESSORIES",
        image: polyFittings,
        alt: "Poly Fittings",
        text: ["Other fittings and accessories like, start connector, joiner, endcap, tee, bend, grommet are used to install drip laterals and are made of HDPE raw material."],
    }
]

export default productsData