import { useState, useEffect, useRef, useMemo } from "react"
import Contact from "./Contact"
import Header from "./Header"
import Home from "./Home"
import Products from "./Products"
import logo from "../assets/images/LatroLogo.png"

function App() {
    const navRef = useRef();
    const navDarkStyle = {backgroundColor: "var(--darkGreenBkgrd)", color: "var(--darkText)"}
    const navLightStyle = {backgroundColor: "var(--lightGreenBkgrd)", color: "var(--lightText)"}

    // Below code is used for switching dark mode
    const [darkMode, setDarkMode] = useState(true)
    function toggleDarkMode() {
        setDarkMode(prevMode => !prevMode)
    }

    //Below code is used to render fixed navigation menu when header is no longer in viewport.
    const headerSection = useRef();
    const [isIntersecting, setIntersecting] = useState(false)
    // useMemo Hook is used so that IntersectionObserver is memoized/cached and need not be initialized for every render.
    const observer = useMemo(() => new IntersectionObserver(
        ([element]) => setIntersecting(element.isIntersecting)
    ),[])
    useEffect(() => {
        observer.observe(headerSection.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [observer])
    
    //Below code is used to scroll to a section when the section is clicked in nav bar
    const homeSection = useRef();
    const productsSection = useRef();
    const contactSection = useRef();
    function scrollToSection(ref) {
        const clientWidth = ref.current.clientWidth
        // Section start position is determined based on font size changes because of media query in css
        const sectionStart = (() => {
            if(clientWidth <= 280) {
                return (ref.current.offsetTop - 43)
            } else if (clientWidth <= 400) {
                return (ref.current.offsetTop - 53)
            } else {
                return (ref.current.offsetTop - 57)
            }
        })();
        window.scrollTo({
            top: sectionStart,
            behavior: 'smooth',
        });
    }

    return ( 
        <div>
            <Header inputRef={headerSection} darkMode={darkMode} toggleDarkMode={toggleDarkMode}/>
            <nav ref={navRef} className={isIntersecting ? "" : "nav-noHeader"} style={darkMode ? navDarkStyle : navLightStyle}>
                <div onClick={() => scrollToSection(homeSection)}>Home</div>
                <div onClick={() => scrollToSection(productsSection)}>Products</div>
                <div onClick={() => scrollToSection(contactSection)}>Contact</div>
                {!isIntersecting && <img src={logo} className="nav-logo" alt="Latro logo"/>}
            </nav>
            <main>
                <Home inputRef={homeSection} darkMode={darkMode} />
                <Products inputRef={productsSection} darkMode={darkMode} navRef={navRef} />
                <Contact inputRef={contactSection} darkMode={darkMode} />
            </main>
        </div>
    )
}

export default App