import "./Contact.css"
import homeIconBlack from "../assets/icons/home_black_24dp.svg"
import homeIconWhite from "../assets/icons/home_white_24dp.svg"
import phoneIconBlack from "../assets/icons/phone_black_24dp.svg"
import phoneIconWhite from "../assets/icons/phone_white_24dp.svg"
import emailIconBlack from "../assets/icons/email_black_24dp.svg"
import emailIconWhite from "../assets/icons/email_white_24dp.svg"
import placeIconBlack from "../assets/icons/place_black_24dp.svg"
import placeIconWhite from "../assets/icons/place_white_24dp.svg"

function Contact(props) {
    // Google Maps URL doc
    // https://developers.google.com/maps/documentation/urls/get-started
    return (
        <div className={props.darkMode ? "contact-dark": "contact"} ref={props.inputRef}>
            <h1 style={{marginTop: "0px"}}>Contact us</h1>
            <div className="contact-flex-container">
                <div>
                    <h2><img src={props.darkMode ? homeIconWhite : homeIconBlack} alt="home icon"/>Address</h2>
                    <p><a href="https://www.google.com/maps/dir/?api=1&destination=Latro+Irrigation&destination_place_id=ChIJVVVVCRVdUjoRrSCj3wXjUcM">15A, Raja Garden,<br/>Paper Mills Road,<br/>Periyar Nagar,<br/>Chennai - 600082.</a></p>
                    <h2><img src={props.darkMode ? phoneIconWhite : phoneIconBlack} alt="phone icon"/>Phone</h2>
                    <p><a href="tel://+919445552061">9445552061</a></p>
                    <p><a href="tel://044-25502210">044-25502210</a></p>
                    <h2><img src={props.darkMode ? emailIconWhite : emailIconBlack} alt="email icon"/>E-Mail</h2>
                    <p><a href="mailto:latrochennai@gmail.com">latrochennai@gmail.com</a></p>
                </div>
                <div>
                    <h2><img src={props.darkMode ? placeIconWhite : placeIconBlack} alt="email icon"/>Map</h2>
                    <iframe title="Location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.71624552647!2d80.22558401529402!3d13.117153815215628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d1509555555%3A0xc351e305dfa320ad!2sLatro%20Irrigation!5e0!3m2!1sen!2sin!4v1655912578331!5m2!1sen!2sin"
                    height="450" style={{"border": 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Contact