import since1992 from "../assets/images/Since1992.png"
import bestQuality from "../assets/images/BestQuality.png"
import tanhodaLogo from "../assets/images/TanhodaLogo.png"
import tamilNaduImage from "../assets/images/tamilnadu-state-map-black.png"
import kabraLogo from "../assets/images/KabraLogo.png"
import bisLogo from "../assets/images/BISLogo.png"

const aboutData = [
    {
        image: since1992,
        text: "Our company has been started during the year 1992 and we have grown to achieve a turnover around 100 crores per year.",
        alt: "Since 1992",
        order: 2
    },
    {
        image: bestQuality,
        text: "With good quality products and service we became popular among the farmers of Tamil Nadu.",
        alt: "Best Quality",
        order: 1
    },
    {
        image: tanhodaLogo,
        text: "Our company is approved by Tamil Nadu Horticulture Development Agency (TANHODA) so that the state and central subsidy can be availed by the farmers of Tamil Nadu.",
        alt:"TANHODA",
        order: 2
    },
    {
        image: tamilNaduImage,
        text: "We have dealers throughout Tamil Nadu for effective supply and service.",
        alt: "Tamil Nadu",
        order: 1
    },
    {
        image: kabraLogo,
        text: "Our products are manufactured with virgin raw materials and the lateral tubes are manufactured by Kabra Extruder machine which is an Indian high standard machine. It yields high quality end product with precise thickness and diameter.",
        alt: "Kabra",
        order: 2
    },
    {
        image: bisLogo,
        text: "We have BIS license for the following products: emitter, emitting pipe (inline lateral), plain lateral and HDPE sprinkler pipe.",
        alt: "BIS",
        order: 1
    }
]

export default aboutData