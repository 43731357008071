import React from "react";
import "./Slider.css";
import leftArrow from "../assets/icons/left-arrow.svg";
import rightArrow from "../assets/icons/right-arrow.svg";

export default function BtnSlider({ direction, moveSlide }) {
    return (
        <button onClick={moveSlide} className="btn-slide">
            <img src={direction === "next" ? rightArrow : leftArrow} alt={direction === "next" ? "Right arrow" : "Left arrow"}/>
        </button>
    );
}