import { useState, useEffect, useRef } from "react"
import "./Products.css"
import productsData from "../data/productsData"

function Products(props) {
    // Below code is used to check whether device is mobile or desktop
    const [deviceType, setDeviceType] = useState("");
    useEffect(() => {
        let hasTouchScreen = false;
        if ("maxTouchPoints" in navigator) {
            hasTouchScreen = navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
            hasTouchScreen = navigator.msMaxTouchPoints > 0;
        } else {
            const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
            if (mQ && mQ.media === "(pointer:coarse)") {
                hasTouchScreen = !!mQ.matches;
            } else if ("orientation" in window) {
                hasTouchScreen = true; // deprecated, but good fallback
            } else {
                // Only as a last resort, fall back to user agent sniffing
                var UA = navigator.userAgent;
                hasTouchScreen = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
            }
        }
        if (hasTouchScreen) {
            setDeviceType("Mobile");
        } else {
            setDeviceType("Desktop");
        }
    }, []);

    // Below code is used for horizontal scroll
    function useHorizontalScroll() {
        const ref = useRef();
        const refCurrent = typeof(ref.current) !== "undefined" ? ref.current : null
        useEffect(() => {
            if(refCurrent !== null && deviceType === "Desktop") { /* Forces horizontal scroll when scrolled using mouse scroll wheel */
                const initLeftOffset = refCurrent.offsetLeft
                const initRightOffset = ((refCurrent.clientWidth / 2) - (window.innerWidth - (refCurrent.clientWidth / 2) - initLeftOffset)) * -1
                let scrollSpace = initRightOffset
                const onWheel = (e) => {
                    if (e.deltaY === 0) return;
                    const test = scrollSpace + e.deltaY
                    if(test >= initRightOffset && test <= initLeftOffset) {
                        e.preventDefault();
                        window.scrollTo({top: props.inputRef.current.offsetTop - props.navRef.current.clientHeight, behavior: 'smooth'})
                        scrollSpace = scrollSpace + e.deltaY
                        refCurrent.style.right = scrollSpace + "px"
                        refCurrent.style.scrollBehavior = "smooth"
                        if((scrollSpace + e.deltaY) > 0 && scrollSpace < initLeftOffset) {
                            scrollSpace = initLeftOffset - e.deltaY
                        }
                        if((scrollSpace + e.deltaY) < initRightOffset && scrollSpace !== initRightOffset) {
                            scrollSpace = initRightOffset - e.deltaY
                        }
                    }
                };
                refCurrent.addEventListener('wheel', onWheel);
                return () => refCurrent.removeEventListener('wheel', onWheel);
            } else if(refCurrent !== null && deviceType === "Mobile") { /* Normal horizontal scroll for touch screens */
                refCurrent.style.position = "initial"
                Array.from(refCurrent.children).map((child) => child.style.flexShrink = 0)
            }
        }, [refCurrent])
        return ref
    }
    
    return(
        <div className={props.darkMode ? "products-dark": "products"} ref={props.inputRef} >
            <h1 style={{marginTop: "0px"}}>Products</h1>
            <div className="media-scroller snaps-inline" ref={useHorizontalScroll()}>
                {productsData.map((data, index) => {
                    return (
                        <div key={index} className="media-element">
                            <h2>{data.title}</h2>
                            <img src={data.image} alt={data.alt}/>
                            <ul>
                                {data.text.map((listItem, index) => {
                                    return (
                                    <li key={index}>{listItem}</li>
                                )})}
                            </ul>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Products